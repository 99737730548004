<template>
  <div class="wx-container">
    <img class="logoIcon" :src="warnImg" >
  </div>
</template>

<script>
	import Api from "@/server";
	export default {
		data() {
			return {
        warnText: '',
        warnImg: '',
        sucImg: require('../../assets/images/wxlogin/haslogin.png'),
        errImg: require('../../assets/images/wxlogin/hasbind.png'),
		errImg1: require('../../assets/images/wxlogin/nowbangd.png'),
      }
    },
    mounted() {
			
      let url = window.location.href,
          hasLogin = Api.getUrlParams(url,'hasLogin');
		  let hasBind = Api.getUrlParams(url,'hasBind');
		  
      if(hasLogin == 'true'){
        this.warnImg = this.sucImg;
        // this.warnText = '登录成功';
      }else{
		  if(hasBind=='false'){
			  this.warnImg = this.errImg1;
		  }else{
			 this.warnImg = this.errImg; 
		  }
        
        // this.warnText = '登录失败';
      }
    },
		methods:{
			// 获取系统名字
			getSysName() {
				let urls = this.gup("syscode")
				this.urls = urls
				let params = {
					"systemId": urls
				};
				Api.ajaxRequest('system/query/by/systemId', params).then(res => {
					let {code,data} = res;
					if (code == '200') {
						if (res.data.systemName == null) {
							this.systemName = '上虞区智慧旅游平台';
						} else {
							this.systemName = res.data.systemName;
						}
					} else {
						this.systemName = '上虞区智慧旅游平台';
					}
				});
			},
			gup(name) {
				var regexS = "[\\?&]" + name + "=([^&#]*)"; //匹配name参数对  
				var regex = new RegExp(regexS);
				var results = regex.exec(window.location.href); //过滤超链接  
				if (results == null) {
					return "";
				} else {
					return results[1];
				}
			},
		}
  }
</script>

<style lang="less" scoped>
  .wx-container{
    width: 100vw;
    height: 100vh;
    // background-image: url('../../assets/images/wxlogin/bg.png');
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding-top: 6vh;
	overflow: hidden;
    
    img{
		width: 100%;
		height: auto;
	}
    
    
    
    
    
    
    
    
  }
</style>
