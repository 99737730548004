import axios from 'axios'
axios.defaults.withCredentials=true
const appServer = axios.create({
  timeout: 60000,
  method: 'post'
 })
 appServer.interceptors.response.use(function (response) {
  let {data} = response 
  return data
 /*  if (data.state > 0 ) {  
    return data
  }  */
 /*  Message({
    type: 'error',
    message: '数据出错'
  }) */
  //return Promise.reject(data.msg)
  //return response;
}, function (error) {  
  return Promise.reject(error);
});
export default appServer